



































































































































































import { Component, Vue } from 'vue-property-decorator'

import NodeCard from '@/components/NodeCard.vue'
import NodeTable from '@/components/NodeTable.vue'
import { NodeInfo, NodeInfoFunctions } from '@/types/state'


import store from '@/store'
import { UserRole } from '@/enums'


@Component({
    components: {
      NodeCard,
      NodeTable
    }
})
export default class DefaultHome extends Vue {
  searchValue = ''
  sortOrder = 0
  filterOnline = 0
  filterChanges = 0
  filterPlacement = 0

  nodeListView = false

  resetFilters() {
    this.searchValue = ''
    this.filterOnline = 0
    this.filterChanges = 0
    this.filterPlacement = 0
  }

  containsTerm(target: string, term: string): boolean {
    return target?.toLowerCase().includes(term?.toLowerCase())
  }

  filterNodesByAlias(nodes: Array<NodeInfo>): Array<NodeInfo> {
    return (this.sortOrder == 0)
      ? NodeInfoFunctions.sortDesc(nodes)
      : NodeInfoFunctions.sortAsc(nodes)
  }

  addToComparison(node: NodeInfo): void {
    this.$store.commit('default/ADD_TO_COMPARISON', { ...node })
  }

  setDetailNode(node: NodeInfo): void {
    this.$store.commit('detailNode/SET_NODE_INFO', { ...node })
    this.$store.commit('meters/SET_NODE_INFO', { ...node })
    this.$store.commit('modNode/SET_NODE_INFO', { ...node })
    this.$store.commit('emraPageInfo/SET_NODE_INFO', { ...node } )
    this.$router.push({ name: this.userRole == UserRole.User ? "DataPage" : (this.userRole != UserRole.None ? "ModEMRAPage" : "UserHome") })
  }
  
  get filteredNodes(): Array<NodeInfo> {
    const filtered = this.nodes.filter(n =>
      (!this.nodeListView
        ? (this.containsTerm(n.alias, this.searchValue)
          || (this.isModOrAdmin && n.emraSerialId ? this.containsTerm("emra-" + n.emraSerialId, this.searchValue) : false))
        : true)
      && !(n.readyForPlacement || n.prepared || n.toBePrepared)
      && (this.filterOnline === 0 ? true
        : (this.filterOnline === 1 ? n.nodeStatus?.label == "Up-to-date"
        : (this.filterOnline === 2 ? n.nodeStatus?.label == "Recent" : n.nodeStatus?.label == "Offline")))
      && (this.filterChanges === 0 ? true : (this.filterChanges === 1 ? this.updatedNodes.includes(n.id) : !this.updatedNodes.includes(n.id)))
      && (this.filterPlacement === 0 ? true : (this.filterPlacement === 1 ? this.nodesPlacedWithinMonth.includes(n.id) : !this.nodesPlacedWithinMonth.includes(n.id)))
    )
    
    return this.filterNodesByAlias(filtered)
  }

  get sortOrderIcon(): string {
    switch(this.sortOrder) {
      case 0:   return 'mdi-sort-alphabetical-ascending'
      default:  return 'mdi-sort-alphabetical-descending'
    }
  }

  get nodes(): Array<NodeInfo> {
    return this.$store.getters['default/NodeInfos'].filter(
      (n: NodeInfo) => !(n.outOfUse || n.readyForPlacement || n.prepared || n.toBePrepared))
  }

  get nodesOnline(): number {
    return this.nodes.filter((n: NodeInfo) => n.nodeStatus?.label == "Up-to-date").length
  }

  get updatedNodes(): Array<any> {
      return [... new Set(this.$store.getters["default/UpdatedMeters"]?.map((um: any) => um.nodeId))]
  }

  get nodesPlacedWithinMonth(): Array<string> {
    const now = Date.now()
    const inRange = (d1: number) => (d1 - now)/(1000*60*60*24) >= 0 && (d1 - now)/(1000*60*60*24) <= 31

    return this.nodes
      .filter((n: NodeInfo) => n.placementDate && inRange(new Date(n.placementDate).getTime()))
      .map((n: NodeInfo) => n.id)
  }

  get placementsWithinMonth(): number {
    return this.nodesPlacedWithinMonth.length
  }

  get isLoaded(): boolean {
    return this.$store.getters['default/IsPreloaded']
  }

  get userRole(): string {
    return this.$store.getters['identity/role']
  }

  get isModOrAdmin(): boolean {
    return this.userRole == 'Moderator' || this.userRole == 'Admin'
  }

  created(): void {
    this.$store.commit('detailNode/SET_PRELOADED', false)
  }
}
